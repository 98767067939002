import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  terms: {
    padding: "30px 70px",

    [`@media screen and (max-width: 1200px)`]: {
      padding: "30px 50px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "30px 20px",
    },
  },
  termHeaderBox: {
    padding: "0 70px 60px",

    [`@media screen and (max-width: 1200px)`]: {
      padding: "0 50px 60px",
    },
    [`@media screen and (max-width: 700px)`]: {
      padding: "0 20px 60px",
    },
  },
  termHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    width: "18%",
    padding: "40px 0",
    "&:hover": {
      cursor: "pointer",
    },
  },
  termsTitle: {
    padding: "50px 0 30px",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "1.8em",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "1.5em",
    },
  },
  definitionList: {
    display: "flex",
  },

  termsText: {
    fontSize: "16px",
    fontWeight: "500",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  termsSubtitle: {
    fontSize: "21px",
    fontWeight: "700",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  termsSubtitleFirst: {
    minWidth: "40px",
    fontSize: "21px",
    fontWeight: "700",
    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "19px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "17px",
    },
  },
  definitionTitle: {
    minWidth: "300px",
    fontSize: "16px",
    fontWeight: "700",
    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
      minWidth: "150px",
    },
  },
  definitionText: {
    fontSize: "16px",
    fontWeight: "500",

    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
  termsList: {
    display: "flex",
  },
  subtitleTermsList: {
    display: "flex",
    margin: "50px 0 20px",
  },
  termsListTitle: {
    minWidth: "40px",
    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "16px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "14px",
    },
  },
  termsListText: {
    [`@media screen and (max-width: 1200px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "13px",
    },
  },
}));

interface TermsPageProps {}

export const TermsPage: React.FC<TermsPageProps> = () => {
  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className={classes.termHeaderBox}>
        <h1 className={classes.termsTitle}>TERMS AND CONDITIONS</h1>
        <div className={classes.termsText}>
          Please read these Terms and Conditions, carefully before registering
          for a subscription for the Services offered on this website operated
          by Node Monitoring FZCO of Dubai Silicon Oasis, DDP, Building A1,
          Dubai, United Arab Emirates a company registered in Dubai with
          registered company number DSO-FZCO-33131. By registering for a free
          trial or completing the online registration form for a chargeable
          subscription for the Services at{" "}
          <a href="https://nodemonitoring.io" target="_blank" rel="noreferrer">
            https://nodemonitoring.io
          </a>{" "}
          and clicking on the accept buttons relating to our Terms and
          Conditions, DPA and Privacy Policy, you the Customer agree to be
          legally bound by these Terms and Conditions, DPA and Privacy Policy as
          they may be modified and posted on our website from time to time. In
          the event of any inconsistency between the content of the Terms and
          Conditions, DPA and the Privacy Policy, the Terms and Conditions shall
          prevail followed by the DPA, and then the Privacy Policy. If you do
          not wish to be bound by these Terms and Conditions, DPA and Privacy
          Policy then you may not register to use a free trial or purchase our
          Services.
        </div>
      </div>
      <div className={classes.terms}>
        <dl className={classes.termsList}>
          <dt className={classes.termsSubtitleFirst}>1.</dt>
          <dd className={classes.termsSubtitle}>Definitions</dd>
        </dl>
        In this Agreement, the following words shall have the following
        meanings:
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Agreement"</dt>
          <dd className={classes.definitionText}>
            means these Terms and Conditions, DPA and Privacy Policy together
            with an Order Form;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Business Day"</dt>
          <dd className={classes.termsText}>
            means 12 pm to 10 pm (UTC) Monday to Friday (excluding any national
            holiday in the UAE);
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Commencement Date"</dt>
          <dd className={classes.termsText}>
            means the date this Agreement commences, as set out in the Order
            Form;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Company"</dt>
          <dd className={classes.termsText}>means Node Monitoring FZCO;</dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>
            "Confidential Information"
          </dt>
          <dd className={classes.termsText}>
            means any and all information in whatsoever form relating to the
            Company or the Customer, or the business, prospective business,
            finances, technical processes, computer software (both source code
            and object code), Intellectual Property Rights or finances of the
            Company or the Customer (as the case may be), or compilations of two
            or more items of such information, whether or not each individual
            item is in itself confidential, which comes into a party’s
            possession by virtue of its entry into this Agreement or provision
            of the Services, and which the party regards, or could reasonably be
            expected to regard, as confidential and any and all information
            which has been or may be derived or obtained from any such
            information;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Customer Data"</dt>
          <dd className={classes.termsText}>
            means all data imported into the Services for the purpose of using
            the Services or facilitating the Customer’s use of the Services;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Customer"</dt>
          <dd className={classes.termsText}>
            means the company or person who completes the Order Form to register
            to use the Services;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"DPA"</dt>
          <dd className={classes.termsText}>
            means the data processing agreement of the Company published at:{" "}
            <a
              href="https://nodemonitoring.io/dpa"
              target="_blank"
              rel="noreferrer"
            >
              https://nodemonitoring.io/dpa
            </a>{" "}
            as amended from time to time;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Effective Date"</dt>
          <dd className={classes.termsText}>
            means the date on which the chargeable subscription for the Services
            commences, as set out in the Order Form;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Feedback"</dt>
          <dd className={classes.termsText}>
            means feedback, innovations or suggestions provided by the Customer
            or users regarding the attributes, performance, suggested
            improvements or features of the Services;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Fees"</dt>
          <dd className={classes.termsText}>
            means the fees in the Order Form for the Services purchased and set
            out in each invoice issued to the Customer during the Term;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Force Majeure"</dt>
          <dd className={classes.termsText}>
            means anything outside the reasonable control of a party, including
            but not limited to, acts of God, fire, storm, flood, earthquake,
            explosion, accident, acts of the public enemy, war, rebellion,
            insurrection, sabotage, epidemic, pandemic, quarantine restriction,
            labour dispute, labour shortage, power shortage, including without
            limitation where Company ceases to be entitled to access the
            Internet for whatever reason, transportation embargo, failure or
            delay in transportation, any act or omission (including laws,
            regulations, disapprovals or failures to approve) of any government
            or government agency;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>
            "Intellectual Property Rights"
          </dt>
          <dd className={classes.termsText}>
            means all copyrights, patents, utility models, trademarks, service
            marks, registered designs, moral rights, design rights (whether
            registered or unregistered), technical information, know-how,
            database rights, semiconductor topography rights, business names and
            logos, computer data, generic rights, proprietary information rights
            and all other similar proprietary rights (and all applications and
            rights to apply for registration or protection of any of the
            foregoing) as may exist anywhere in the world;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Order Form"</dt>
          <dd className={classes.termsText}>
            means the online order form completed by the Customer to register
            for a subscription to use the Services;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Privacy Policy"</dt>
          <dd className={classes.termsText}>
            means the privacy policy of the Company published at:{" "}
            <a
              href="https://nodemonitoring.io/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://nodemonitoring.io/privacy
            </a>{" "}
            as amended from time to time;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Renewal Term"</dt>
          <dd className={classes.termsText}>
            means the renewal term set out in the Order Form;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Services"</dt>
          <dd className={classes.termsText}>
            means the software applications services of the Company, ordered
            online by the Customer and set out in the confirmation email sent to
            the Customer which are made available to the Customer in accordance
            with the terms of this Agreement, including any computer software
            programmes and, if appropriate, Updates thereto;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Statistical Data"</dt>
          <dd className={classes.termsText}>
            means aggregated, anonymised data derived from the Customer or
            user’s use of the Services which does not include any personal data
            or Customer Confidential Information;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Term"</dt>
          <dd className={classes.termsText}>
            means the Trial Period, plus all Renewal Terms together;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Terms and Conditions"</dt>
          <dd className={classes.termsText}>
            means these terms and conditions of the Company, published at:{" "}
            <a
              href="https://nodemonitoring.io/terms"
              target="https://nodemonitoring.io/terms"
            >
              https://nodemonitoring.io/terms
            </a>{" "}
            as amended from time to time;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Trial Period"</dt>
          <dd className={classes.termsText}>
            means a fixed period of 30 days (unless stated otherwise in the
            Order Form) starting on the Commencement Date;
          </dd>
        </dl>
        <dl className={classes.definitionList}>
          <dt className={classes.definitionTitle}>"Updates"</dt>
          <dd className={classes.termsText}>
            means any new or updated applications services or tools (including
            any computer software programmes) made available by the Company as
            part of the Services.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>2.</dt>
          <dd className={classes.termsSubtitle}>Services</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>2.1</dt>
          <dd className={classes.termsListText}>
            The Customer engages the Company and the Company agrees to provide
            the Services to the Customer from the Commencement Date for the
            Term.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>2.2</dt>
          <dd className={classes.termsListText}>
            The Services shall be made available to the Customer in accordance
            with and subject to the provisions of this Agreement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>2.3</dt>
          <dd className={classes.termsListText}>
            The Services shall be available to the Customers 99.9% in each
            calendar month. Availability excludes any downtime caused by
            schedules maintenance which is carried out on the Services from time
            to time.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>3.</dt>
          <dd className={classes.termsSubtitle}>Licence</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.1</dt>
          <dd className={classes.termsListText}>
            The Customer is granted a non-exclusive, non-transferable,
            worldwide, revocable licence to use the Services (including any
            associated software, Intellectual Property Rights and Confidential
            Information of the Company) during the Term, (subject to payment of
            any applicable Fees after expiry of the Trial Period). Such licence
            permits the Customer to make cache copies of software or other
            information as are required for the Customer to receive the Services
            via the Internet. Where open source software is used as part of the
            Services, such software use by the Customer will be subject to the
            terms of the open source licences.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.2</dt>
          <dd className={classes.termsListText}>
            No right to modify, adapt, or translate the Services or create
            derivative works from the Services is granted to the Customer.
            Nothing in this Agreement shall be construed to mean, by inference
            or otherwise, that the Customer has any right to obtain source code
            for the software comprised within the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.3</dt>
          <dd className={classes.termsListText}>
            Disassembly, decompilation or reverse engineering and other source
            code derivation of the software comprised within the Services is
            prohibited. To the extent that the Customer is granted the right by
            law to decompile such software in order to obtain information
            necessary to render the Services interoperable with other software
            (and upon written request by the Customer identifying relevant
            details of the Services(s) with which interoperability is sought and
            the nature of the information needed), the Company will provide
            access to relevant source code or information. The Company has the
            right to impose reasonable conditions including but not limited to
            the imposition of a reasonable fee for providing such access and
            information.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.4</dt>
          <dd className={classes.termsListText}>
            Unless otherwise specified in this Agreement, the Services are
            provided and may be used solely by the Customer and its authorised
            users as part of the Customer’s website/desktop architecture. Except
            as specifically stated in this Agreement, the Customer may not: (i)
            lease, loan, resell or otherwise distribute the Services save as
            permitted in writing by the Company; (ii) use the Services to
            provide ancillary services related to the Services; or (iii) permit
            access to or use of the Services by or on behalf of any third party.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.5</dt>
          <dd className={classes.termsListText}>
            The Customer warrants and represents that it shall maintain
            reasonable security measures (as may change over time) covering,
            without limitation, confidentiality, authenticity and integrity to
            ensure that the access to the Services granted under this Agreement
            is limited as set out under this Agreement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>3.6</dt>
          <dd className={classes.termsListText}>
            The Company may suspend access to the Services, or portion thereof,
            at any time, if in the Company’s sole reasonable discretion, the
            integrity or security of the Services is in danger of being
            compromised by acts of the Customer or its users or the Customer is
            in breach of the terms of the Agreement. Where possible, the Company
            shall give the Customer 24 hours written notice, before suspending
            access to the Services, giving specific details of its reasons.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>4.</dt>
          <dd className={classes.termsSubtitle}>
            Intellectual property rights
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.1</dt>
          <dd className={classes.termsListText}>
            All Intellectual Property Rights and title to the Services (save to
            the extent incorporating any Customer Data, or Customer or third
            party owned item) shall remain with the Company and/or its licensors
            and subcontractors. No interest or ownership in the Services, the
            Company’s Intellectual Property Rights or otherwise is transferred
            to the Customer under this Agreement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.2</dt>
          <dd className={classes.termsListText}>
            The Customer shall retain sole ownership of all rights, title and
            interest in and to Customer Data and its pre-existing Intellectual
            Property Rights and shall have the sole responsibility for the
            legality, reliability, integrity, accuracy and quality of the
            Customer Data. The Customer grants the Company a non-exclusive,
            licence to use Customer Data, Customer Intellectual Property Rights
            and any third party owned item from the Commencement Date, for the
            Term, to the extent required for the Company to provide the
            Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.3</dt>
          <dd className={classes.termsListText}>
            The Customer is not allowed to remove any proprietary marks or
            copyright notices from the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.4</dt>
          <dd className={classes.termsListText}>
            The Customer grants the Company a non-exclusive, non-transferable,
            revocable licence to use the Customer’s name, logo and trademarks,
            as designated and/or amended by the Customer from time to time for
            the sole purpose of the Company providing the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.5</dt>
          <dd className={classes.termsListText}>
            The Customer assigns all rights, title and interest in any Feedback
            to the Company. If for any reason such assignment is ineffective,
            the Customer shall grant the Company a non-exclusive, perpetual,
            irrevocable, royalty free, worldwide right and licence to use,
            reproduce, disclose, sub- licence, distribute, modify and exploit
            such Feedback without restriction.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.6</dt>
          <dd className={classes.termsListText}>
            The Customer grants the Company the perpetual right to use
            Statistical Data and nothing in this Agreement shall be construed as
            prohibiting the Company from using the Statistical Data for business
            and/or operating purposes, provided that the Company does not share
            with any third party Statistical Data which reveals the identity of
            the Customer or Customer’s Confidential Information.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>4.7</dt>
          <dd className={classes.termsListText}>
            The Company may take and maintain technical precautions to protect
            the Services from improper or unauthorised use, distribution or
            copying.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>5.</dt>
          <dd className={classes.termsSubtitle}>
            Ordering, fees, invoicing and payments
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.1</dt>
          <dd className={classes.termsListText}>
            The Company is entitled to refuse any order placed by a Customer. If
            an order is accepted, the Company shall confirm acceptance via
            email. No Fees shall be charged for use of the Services during the
            Trial Period.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.2</dt>
          <dd className={classes.termsListText}>
            Upon expiry of the Trial Period the Company shall charge the
            Customer the Fees from the Effective Date for each Renewal Term.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.3</dt>
          <dd className={classes.termsListText}>
            Fees are calculated as set out in the Order Form.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.4</dt>
          <dd className={classes.termsListText}>
            Invoices shall be issued to the Customer in the currency set out in
            the Order Form. All invoices shall be payable in full by the
            Customer. All Fees exclude any Value Added Tax legally (or other
            applicable sales tax) payable on the date of the invoice, which
            shall be paid by the Customer in addition, where applicable.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.5</dt>
          <dd className={classes.termsListText}>
            The Customer shall invoice all Fees as set out in the Order Form.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.6</dt>
          <dd className={classes.termsListText}>
            All Fees are payable by credit card, within 30 days of the date of
            each invoice.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.7</dt>
          <dd className={classes.termsListText}>
            Where payment of any Fees is not received when due, the Company may,
            without liability to the Customer, disable the Customer’s password,
            account and access to all or part of the Services and the Company
            shall be under no obligation to provide any or all of the Services
            while the invoice(s) concerned remains unpaid. The Company shall be
            entitled to charge interest on overdue Fees at the applicable
            statutory rate.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.8</dt>
          <dd className={classes.termsListText}>
            The Company reserves the right to recover any costs and reasonable
            legal fees it incurs in recovering overdue payments.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>5.9</dt>
          <dd className={classes.termsListText}>
            The Company is entitled to increase any Fees upon giving the
            Customer 30 days prior written notice of changes. Increases shall
            apply from the start of the next applicable Renewal Term, unless the
            Customer terminates the Agreement before this date.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>6.</dt>
          <dd className={classes.termsSubtitle}>Warranties</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.1</dt>
          <dd className={classes.termsListText}>
            Each party warrants and represents that: (i) it has full corporate
            power and authority to enter into this Agreement and to perform the
            obligations required hereunder; (ii) the execution and performance
            of its obligations under this Agreement does not violate or conflict
            with the terms of any other agreement to which it is a party and is
            in accordance with any applicable laws; and (iii) it shall respect
            all applicable laws and regulations, governmental orders and court
            orders, which relate to this Agreement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.2</dt>
          <dd className={classes.termsListText}>
            The Company warrants to the Customer that: (i) it has the right to
            license the Services; (ii) the Services shall be performed with
            reasonable skill and care and in a professional manner in accordance
            with good industry practice; (iii) in performing the Services it
            will not infringe the Intellectual Property Rights of any third
            party or be in breach of any obligations it may have to a third
            party. The foregoing warranties shall not: (a) cover deficiencies or
            damages relating to any third party components not furnished by the
            Company; or (b) any third party provided connectivity necessary for
            the provision or use of the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.3</dt>
          <dd className={classes.termsListText}>
            No warranty is made regarding: (i) the results of using the
            Services; (ii) that the functionality of the Services will meet the
            requirements of the Customer; or (iii) that the Services will
            operate uninterrupted or error free.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.4</dt>
          <dd className={classes.termsListText}>
            The Customer warrants and represents to the Company that: (i) it
            rightfully owns the necessary user rights, copyrights and ancillary
            copyrights and permits required for it to fulfil its obligations
            under this Agreement; (ii) it shall maintain reasonable security
            measures (as may change over time) covering, without limitation,
            confidentiality, authenticity and integrity to ensure that the
            access to the Services granted under this Agreement is limited as
            set out under this Agreement. In particular the Customer and users
            shall treat any identification, password or username or other
            security device for use of the Services with due diligence and care
            and take all necessary steps to ensure that they are kept
            confidential, secure and are used properly and are not disclosed to
            unauthorised persons. Any breach of the above shall be immediately
            notified to the Company in writing. The Customer shall be liable for
            any breach of the terms of this Agreement by its users; and (iii) it
            shall ensure that its network and systems comply with the relevant
            specification provided by the Company from time to time and that it
            is solely responsible for procuring and maintaining its network
            connections and telecommunications links from the Customer’s systems
            to the Company’s data centres and all problems, conditions, delays,
            delivery failures and all other loss or damage arising from or
            relating to the Customer’s network connections or telecommunications
            links or caused by the Internet.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.5</dt>
          <dd className={classes.termsListText}>
            All third party content or information provided by the Company via
            the Services, is provided “as is”. The Company provides no
            warranties in relation to such content or information and shall have
            no liability whatsoever to the Customer for its use or reliance upon
            such content or information.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>6.6</dt>
          <dd className={classes.termsListText}>
            Except as expressly stated in this Agreement, all warranties and
            conditions, whether express or implied by statute, common law or
            otherwise (including but not limited to satisfactory quality and
            fitness for purpose), are hereby excluded to the fullest extent
            permitted by law.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>7.</dt>
          <dd className={classes.termsSubtitle}>Liability</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.1</dt>
          <dd className={classes.termsListText}>
            Neither party excludes or limits its liability to the other for
            fraud, death or personal injury caused by any negligent act or
            omission, or wilful misconduct.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.2</dt>
          <dd className={classes.termsListText}>
            In no event shall either party be liable to the other whether
            arising under this Agreement or in tort (including negligence or
            breach of statutory duty), misrepresentation or however arising, for
            any Consequential Loss. (“<strong>Consequential Loss</strong>” shall
            for the purposes of this section mean: (i) pure economic loss; (ii)
            losses incurred by any client of the Customer or other third party;
            (iii) loss of profits (whether categorised as direct or indirect
            loss); (iv) losses arising from business interruption; (v) loss of
            business revenue, goodwill or anticipated savings; (vi) losses
            whether or not occurring in the normal course of business; or (vii)
            wasted management or staff time.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.3</dt>
          <dd className={classes.termsListText}>
            Subject to clauses 7.1 and 7.2, the total liability of the Company
            to the Customer in aggregate (whether in contract, tort or
            otherwise) under or in connection with this Agreement or based on
            any claim for indemnity or contribution shall be limited to one
            hundred (100) per cent of the total Fees (excluding any VAT, duty,
            sales or similar taxes) paid or payable by the Customer to the
            Company during the twelve (12) month period before the date on which
            any claim arose. If the duration of the Agreement has been less than
            twelve (12) months, such shorter period shall apply.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.4</dt>
          <dd className={classes.termsListText}>
            The Customer shall be liable for any breaches of this Agreement
            caused by the acts, omissions or negligence of any users who access
            the Services as if such acts, omissions or negligence had been
            committed by the Customer itself.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.5</dt>
          <dd className={classes.termsListText}>
            In no event shall the Customer raise any claim under this Agreement
            more than one (1) year after: (i) the discovery of the circumstances
            giving rise to such claim; or (ii) the effective date of the
            termination of this Agreement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>7.6</dt>
          <dd className={classes.termsListText}>
            The parties acknowledge and agree that in entering into this
            Agreement, each had recourse to its own skill and judgement and they
            have not relied on any representation made by the other, its
            employees or agents.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>8.</dt>
          <dd className={classes.termsSubtitle}>Services</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>8.1</dt>
          <dd className={classes.termsListText}>
            The Company, shall at its own expense: (i) defend, or at its option,
            settle any claim or suit brought against the Customer by a third
            party on the basis of infringement of any Intellectual Property
            Rights by the Services (excluding any claim or suit deriving from
            any Customer provided item); and (ii) pay any final judgement
            entered against the Customer on such issue or any settlement of the
            claim, provided that: (a) the Customer notifies the Company promptly
            of each such claim or suit; (b) the Company is given sole control of
            the defence and/or settlement; and the (c) Customer fully
            co-operates and provides all reasonable assistance to the Company in
            the defence or settlement.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>8.2</dt>
          <dd className={classes.termsListText}>
            If all or any part of the Services becomes, or in the opinion of the
            Company may become, the subject of a claim or suit of infringement,
            the Company at its own expense and sole discretion may: (i) procure
            for the Customer the right to continue to use the Services or the
            affected part thereof; or (ii) replace the Services or affected part
            with other suitable non-infringing service(s); or (iii) modify the
            Services or affected part to make the same non-infringing.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>8.3</dt>
          <dd className={classes.termsListText}>
            The Company shall have no obligations under this clause 8 to the
            extent that a claim is based on: (i) the combination, operation or
            use of the Services with other services or software not provided by
            the Company, if such infringement would have been avoided in the
            absence of such combination, operation or use; or (ii) use of the
            Services in any manner inconsistent with the terms of this
            Agreement; or (iii) the negligence or wilful misconduct of the
            Customer.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>8.4</dt>
          <dd className={classes.termsListText}>
            The Customer shall defend, indemnify and hold the Company and its
            employees, sub- contractors and agents harmless from and against any
            cost, losses, fines, liabilities and expenses, including reasonable
            legal costs arising from any claim relating to or resulting directly
            or indirectly from: (i) any claimed infringement or breach by the
            Customer of any Intellectual Property Rights with respect to the
            Customer’s use of the Services outside the scope of this Agreement;
            (ii) use by the Company of any Customer Data or Customer or user
            provided item; (iii) the Customer’s breaches of its obligations
            under data protection law or regulations or the terms of the DPA;
            and (iv) any breach of the terms of this Agreement by the Customer’s
            users.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>8.5</dt>
          <dd className={classes.termsListText}>
            Subject to clauses 8.1 to 8.4 inclusive, each party (‘
            <strong>the first party</strong>’) indemnifies and undertakes to
            keep indemnified the other party, its officers, servants and agents
            (‘<strong>the second party</strong>’) against any costs or expenses
            (including the cost of any settlement) arising out of any claim,
            action, proceeding or demand that may be brought, made or prosecuted
            against the second party under clause 8 of this Agreement. Such
            indemnity extends to and includes all costs, damages and expenses
            (including legal fees and expenses) reasonably incurred by the
            second party in defending any such action, proceeding claim or
            demands.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>9.</dt>
          <dd className={classes.termsSubtitle}>Term and termination</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.1</dt>
          <dd className={classes.termsListText}>
            This Agreement starts on the Commencement Date and continues for the
            Trial Period. Upon expiry of the Trial Period, the Agreement shall
            automatically renew from the Effective Date for successive Renewal
            Terms until either party terminates the Agreement in accordance with
            its rights set out below.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.2</dt>
          <dd className={classes.termsListText}>
            The Company may immediately terminate this Agreement or the
            provision of any Services provided pursuant to this Agreement if:
            (i) the Customer has used or permitted the use of the Services
            otherwise than in accordance with this Agreement; or (ii) the
            Company is prohibited, by law from providing the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.3</dt>
          <dd className={classes.termsListText}>
            Either party may terminate this Agreement at any time by giving at
            least 30 days written notice prior to the start of any Renewal Term.
            Such notice shall be effective from the start date of the next
            applicable Renewal Term.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.4</dt>
          <dd className={classes.termsListText}>
            Either party shall be entitled to terminate this Agreement on
            written notice to the other party if the other party: (i) goes into
            voluntary or involuntary liquidation (otherwise than for the purpose
            of a solvent reconstruction or amalgamation) or has a receiver or
            administrator or similar person appointed or is unable to pay its
            debts within the meaning of s268 Insolvency Act 1986 or ceases or
            threatens to cease to carry on business or if any event occurs which
            is analogous to any of the foregoing in another jurisdiction; or
            (ii) commits a material breach of any term of this Agreement which,
            if capable of remedy, is not remedied within seven (7) days of
            receipt of a written notice specifying the breach and requiring it
            to be remedied; (iii) is prevented by Force Majeure from fulfilling
            its obligations for more than twenty eight (28) days.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.5</dt>
          <dd className={classes.termsListText}>
            Upon termination of this Agreement: (i) the Company shall
            immediately cease providing the Services to the Customer and all
            licences granted hereunder shall terminate; (ii) the Customer shall
            promptly pay the Company all unpaid Fees for the remainder of the
            Term. No Fees already paid shall be refunded if the Agreement is
            terminated prior to the end of a Renewal Term; (iii) at the option
            of the Customer, following receipt of a request from the Customer
            delete (in accordance with the terms of the DPA) or return all
            Customer Data stored in the Company’s database in a machine readable
            format, free of charge, provided that such request is made within 30
            days of termination. If the Customer requires any Customer Data to
            be returned in a different format the Company reserves the right to
            charge for this additional service on a time and materials basis.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>9.6</dt>
          <dd className={classes.termsListText}>
            Termination of this Agreement for whatever reason shall not affect
            the accrued rights of the parties. All clauses which by their nature
            should continue after termination shall, for the avoidance of doubt,
            survive the expiration or sooner termination of this Agreement and
            shall remain in force and effect.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitle}>10.</dt>
          <dd className={classes.termsSubtitle}>Services</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>10.1</dt>
          <dd className={classes.termsListText}>
            The recipient of any Confidential Information from a disclosing
            party may only use the disclosing party’s Confidential Information
            for the purposes of this Agreement and must keep confidential all
            Confidential Information except to the extent (if any) the recipient
            of the Confidential Information is required by law to disclose the
            Confidential Information.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>10.2</dt>
          <dd className={classes.termsListText}>
            The recipient of Confidential Information may disclose the
            Confidential Information party to its employees and agents who need
            to know the Confidential Information for the purposes of this
            Agreement, but only if the employee or agent is bound by a
            confidentiality undertaking to keep the Confidential Information
            confidential.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>10.3</dt>
          <dd className={classes.termsListText}>
            Both parties agree to return all documents and other materials
            containing Confidential Information of the other party immediately
            upon completion of the Services.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>10.4</dt>
          <dd className={classes.termsListText}>
            The obligations of confidentiality under this Agreement do not
            extend to information that: (i) was rightfully in the possession of
            the recipient before the negotiations leading to this Agreement took
            place; (ii) is on the Commencement Date, or later becomes public
            knowledge (other than as a result of the recipient’s breach of this
            Agreement); or (iii) the recipient is required by law to disclose.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>11.</dt>
          <dd className={classes.termsSubtitle}>Services</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>11.1</dt>
          <dd className={classes.termsListText}>
            Each party undertakes to comply with its obligations under relevant
            applicable data protection laws, principles and agreements.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>11.2</dt>
          <dd className={classes.termsListText}>
            To the extent that personal data is processed when the Customer or
            its users use the Services, the parties acknowledge that the Company
            is a data processor and the Customer is a data controller and the
            parties shall comply with their respective obligations under
            applicable data protection law and the terms of the DPA.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>11.3</dt>
          <dd className={classes.termsListText}>
            If a third party alleges infringement of its data protection rights,
            the Company shall be entitled to take measures necessary to prevent
            the infringement of a third party’s rights from continuing.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>11.4</dt>
          <dd className={classes.termsListText}>
            Where the Company collects and processes personal data of the
            Customer, as a data controller, when providing the Services to the
            Customer, for example when using the Customer’s personal data to
            issue invoices, such collection and processing shall be in
            accordance with the Privacy Policy.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>12.</dt>
          <dd className={classes.termsSubtitle}>Third parties</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}></dt>
          <dd className={classes.termsListText}>
            Nothing contained in this Agreement is intended to be enforceable by
            a third party, who is not a party to this Agreement, under the any
            statutory right they may have in any applicable jurisdiction.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>13.</dt>
          <dd className={classes.termsSubtitle}>Force majeure</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>13.1</dt>
          <dd className={classes.termsListText}>
            Excluding an obligation to make payments, if a party is wholly or
            partially prevented by Force Majeure from complying with its
            obligations under this Agreement, then that party’s obligation to
            perform in accordance with this Agreement will be suspended.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>13.2</dt>
          <dd className={classes.termsListText}>
            As soon as practicable after an event of Force Majeure arises, the
            party affected by the Force Majeure must notify the other party of
            the extent to which the notifying party is unable to perform its
            obligations under this Agreement. If the Force Majeure event last
            for more than 28 days either party may terminate this Agreement with
            immediate effect, without penalty.
          </dd>
        </dl>
        <dl className={classes.subtitleTermsList}>
          <dt className={classes.termsSubtitleFirst}>14.</dt>
          <dd className={classes.termsSubtitle}>Miscellaneous</dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.1</dt>
          <dd className={classes.termsListText}>
            Should a provision of this Agreement be invalid or become invalid
            then the legal effect of the other provisions shall remain
            unaffected. A valid provision is deemed to have been agreed which
            comes closest to what the parties intended commercially and shall
            replace the invalid provision. The same shall apply to any
            omissions.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.2</dt>
          <dd className={classes.termsListText}>
            This Agreement constitutes the whole agreement and understanding
            between the parties and supersedes all prior agreements,
            representations, negotiations and discussions between the parties
            relating to the subject matter thereof.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.3</dt>
          <dd className={classes.termsListText}>
            No party may assignor transfer its rights under this Agreement
            without the prior written consent of the other party, such consent
            shall not be unreasonably withheld. However the Company shall be
            entitled to assign the Agreement to: (i) any company in the
            Company’s group of companies; or (ii) any entity that purchases the
            shares or assets of the Company as the result of a merger, takeover
            or similar event, who is not a competitor of the Customer.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.4</dt>
          <dd className={classes.termsListText}>
            The Company and the Customer are independent contractors and nothing
            in this Agreement will be construed as creating an employer-employee
            relationship.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.5</dt>
          <dd className={classes.termsListText}>
            Amendments to, or notices to be sent under this Agreement, shall be
            in writing and shall be deemed to have been duly given if: (i) sent
            by international courier to a party at the address given for that
            party in this Agreement; or (ii) to the email address of each party
            usually used to correspond within the Services for invoicing
            purposes. Notices shall be deemed to have been duly given: (a) on
            the day they are sent if they are emailed or delivered with a
            signature of receipt on a Business Day; or (b). if emailed on a
            non-Business Day, on the next Business Day. Notwithstanding the
            aforesaid, the Company may change or modify the terms of this
            Agreement upon giving the Customer 30 days’ notice via email. All
            changes shall be deemed to have been accepted by the Customer unless
            the Customer terminates the Agreement prior to the expiry of the 30
            day period.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.6</dt>
          <dd className={classes.termsListText}>
            Neither party shall make any public statement, press release or
            other announcement relating to the terms or existence of this
            Agreement, or the business relationship of the parties, without the
            prior written consent of the other party. Notwithstanding the
            aforesaid the Company may use the Customer’s name and trademarks
            (logo only) to list the Customer as a client of the Company on its
            website and in other marketing materials and information.
          </dd>
        </dl>
        <dl className={classes.termsList}>
          <dt className={classes.termsListTitle}>14.7</dt>
          <dd className={classes.termsListText}>
            This Agreement shall be governed by the laws of England and Wales.
            The courts of England shall have exclusive jurisdiction for the
            settlement of all disputes arising under this Agreement. 8
          </dd>
        </dl>
      </div>
    </>
  );
};
