import React from "react";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  startSection: {
    backgroundColor: "#E8E9F3",
  },
  startSectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 3.6vw",

    [`@media screen and (max-width: 700px)`]: {
      padding: "0 40px",
    },
  },
  logo: {
    cursor: "pointer",
    height: "6.5vh",
    padding: "3.7vh 0",
    objectFit: "contain",
    alignItems: "center",

    [`@media screen and (max-width: 1600px)`]: {
      height: "60px",
    },
    [`@media screen and (max-width: 700px)`]: {
      display: "none",
    },
  },
  logoMobile: {
    cursor: "pointer",
    display: "flex",
    alignSelf: "center",
    padding: "3.7vh 0",
    height: "30px",
    objectFit: "contain",
    alignItems: "center",

    [`@media screen and (min-width: 700px)`]: {
      display: "none",
    },
  },
  loginButton: {
    ...theme.typography.button2,
    textTransform: "none",
    border: "1px solid #6D7A8D",
    width: "10%",
    minWidth: "6.8vw",
    height: "4.2vh",
    margin: "4.6vh 0",
    borderRadius: "12px",
    "&:hover": {
      background: "linear-gradient(90deg , #25497E, #212121)",
      color: "white",
      border: "0px",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "16px",
      height: "40px",
      width: "11%",
    },
    [`@media screen and (max-width: 1300px)`]: {
      minWidth: "160px",
    },
    [`@media screen and (max-width: 700px)`]: {
      minWidth: "130px",
    },
  },
  startBox: {
    display: "flex",
    flexDirection: "row",
    paddingRight: "2.7vw",
    [`@media screen and (max-width: 1300px)`]: {
      flexDirection: "column",
      paddingRight: "0",
    },
  },
  startInfoBox: {
    width: "43%",
    display: "flex",
    flexDirection: "column",
    padding: "5.4vh 0 8.3vh 12%",
    [`@media screen and (max-width: 1600px)`]: {
      padding: "58px 0 70px 10%",
    },
    [`@media screen and (max-width: 1300px)`]: {
      alignItems: "center",
      width: "80%",
    },
    [`@media screen and (max-width: 700px)`]: {
      alignItems: "center",
      padding: "0 0 70px 10%",
    },
  },
  startTitle: {
    ...theme.typography.h1,
    fontWeight: "bold",
    paddingRight: "4.2vw",

    [`@media screen and (max-width: 1300px)`]: {
      textAlign: "center",
      paddingRight: "0",
    },
    [`@media screen and (max-width: 700px)`]: {
      textAlign: "center",
      fontSize: "28px",
    },
  },
  separator: {
    border: "2px solid #6D7A8D",
    width: "6.6vw",
    margin: "2.8vh 0 3.7vh",
    [`@media screen and (max-width: 700px)`]: {
      margin: "30px 0 22px",
    },
  },
  startDescription: {
    ...theme.typography.subtitle,
    color: "#6D7A8D",
    paddingRight: "4.2vw",
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "14px",
    },
    [`@media screen and (max-width: 1300px)`]: {
      textAlign: "center",
      paddingRight: "0",
      fontSize: "16px",
    },
    [`@media screen and (max-width: 700px)`]: {
      textAlign: "left",
      fontSize: "14px",
    },
  },
  requestTrialButton: {
    ...theme.typography.button1,
    height: "5.6vh",
    width: "60%",
    borderRadius: "12px",
    color: "white",
    backgroundColor: "#000",
    margin: "9.3vh 0 0",
    "&:hover": {
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
    },
    [`@media screen and (max-width: 1600px)`]: {
      fontSize: "18px",
      margin: "80px 0 0",
    },
    [`@media screen and (max-width: 1300px)`]: {
      margin: "50px 0 0",
    },
    [`@media screen and (max-width: 700px)`]: {
      fontSize: "14px",
      width: "75%",
      margin: "50px auto 0",
      border: "0",
      color: "white",
      background: "linear-gradient(90deg , #25497E, #6D7A8D)",
    },
  },
  startImage: {
    width: "50%",
    margin: "0 auto",
    padding: "4.6vh 0 8.3vh 2.6vw",

    [`@media screen and (max-width: 1300px)`]: {
      padding: "20px 0 50px 0",
      width: "70%",
    },
    [`@media screen and (max-width: 700px)`]: {
      display: "none",
    },
  },
  startImageMobile: {
    width: "100%",
    margin: "0 auto",
    padding: "20px 0",

    [`@media screen and (min-width: 700px)`]: {
      display: "none",
    },
  },
}));

interface StartSectionProps {}

export const StartSection: React.FC<StartSectionProps> = () => {
  const { classes } = useStyles();
  const appUrl = process.env.REACT_APP_URL;

  return (
    <div className={classes.startSection}>
      <div className={classes.startSectionHeader}>
        <img
          className={classes.logo}
          src="/images/start-logo.png"
          alt="logo"
          onClick={() => {
            window.scrollTo({ top: 0 });
            window.location.reload();
          }}
        ></img>
        <img
          className={classes.logoMobile}
          src="/images/start-logo-mobile.png"
          alt="logo"
          onClick={() => {
            window.scrollTo({ top: 0 });
            window.location.reload();
          }}
        ></img>
        {/* <Button
          className={classes.loginButton}
          onClick={(e) => {
            e.preventDefault();
            window.location.href = appUrl!;
          }}
        >
          Open App
        </Button> */}
      </div>
      <div className={classes.startBox}>
        <div className={classes.startInfoBox}>
          <h1 className={classes.startTitle}>
            The Only All-in-One Chainlink Node Monitoring Platform.
          </h1>
          <div className={classes.separator}></div>
          <img
            className={classes.startImageMobile}
            src="/images/start-image.png"
            alt="start"
          ></img>
          <div className={classes.startDescription}>
            Save valuable resources through streamlined configuration,
            customized dashboards and best-in-class alerting solutions. Say
            goodbye to time-consuming maintenance.
          </div>
          <Button
            className={classes.requestTrialButton}
            onClick={(e) => {
              e.preventDefault();
              window.open("https://calendly.com/nodemonitoring/30", "_blank");
            }}
          >
            Start Your 30-Day Free Trial
          </Button>
        </div>
        <img
          className={classes.startImage}
          src="/images/start-image.png"
          alt="start"
        ></img>
      </div>
    </div>
  );
};
